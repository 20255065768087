
  import { defineComponent } from 'vue'

  export default defineComponent({
    data() {
      return {}
    },
    props: {
      newsList: {
        type: Array,
        default: () => [] as any[],
      },
      createdAtKey: {
        type: String,
        default: 'created_at',
      },
    },
  })
