
  import { defineComponent } from 'vue'

  export default defineComponent({
    computed: {
      routers: function () {
        return this.$route.matched
      },
    },
    methods: {
      isNowPath(path: string): boolean {
        return this.$route.path === path
      },
    },
  })
