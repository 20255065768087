<template>
  <menu-nav :isWebSiteNavOpen="isWebSiteNavOpen" />
  <footer class="bg-color footer">
    <div class="container main-width h-100">
      <div class="d-flex align-items-center h-100">
        <div class="d-flex d-block-sm">
          <div class="website-name text-white">
            <div>苗栗縣地方產業</div>
            <div>發展策略資訊網</div>
          </div>
          <div class="ms-5 ms-0-sm mb-sm-1">
            <div class="text-white lh-25 fs-14-sm">
              <span class="d-inline me-4 d-block-sm">
                地址：36001 苗栗縣苗栗市縣府路100號
              </span>
              <span class="d-block-sm">總機：037-322150</span>
            </div>
            <div class="address-other-info lh-25">
              <div
                class="border-start text-white d-inline px-2 p-sm-x-1 cursor-pointer"
                @click="$router.push('/open-data-license')"
              >
                <span>政府網站資料開放宣告</span>
              </div>
              <div
                class="border-start border-end text-white d-inline px-2 p-sm-x-1 cursor-pointer"
                @click="$router.push('/privacy-policy')"
              >
                <span>隱私權與資訊安全政策</span>
              </div>
            </div>
            <div class="copyright lh-28">
              <p class="text-white d-inline">
                <span class="me-1">版權所有</span>
                <span class="me-1">苗栗縣政府工商發展處</span>
                <span>Economic Development</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import { defineComponent } from 'vue'
  import MenuNav from '@/components/MenuNav/index.vue'

  export default defineComponent({
    components: { MenuNav },
    data() {
      return {
        isWebSiteNavOpen: false,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .bg-color {
    background: linear-gradient(93.3deg, #042a62 2.91%, #1d7d9b 98.61%);
  }

  .footer {
    height: 136px;
  }

  .copyright {
    font-size: 14px;
  }

  .address-other-info {
    font-size: 16px;
  }

  .website-name {
    font-weight: 500;
    font-size: 23px;
    line-height: 36px;
  }
</style>
