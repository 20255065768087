import { createApp } from 'vue'
import { Pagination, Spin, Skeleton } from 'ant-design-vue'

import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'

createApp(App)
  .use(Pagination)
  .use(Spin)
  .use(Skeleton)
  .use(store)
  .use(router)
  .mount('#app')
