
  import { defineComponent } from 'vue'
  import { mapMutations } from 'vuex'
  import isExternalPath from '@/utils/isExternalPath'

  export default defineComponent({
    name: 'Index',
    setup() {
      return { isExternalPath }
    },
    data() {
      return {
        navs: [
          {
            title: '地方產業發展策略',
            links: [{ title: '地方產業發展策略', to: '/development-strategy' }],
          },
          {
            title: '非都市土地開發許可',
            links: [
              {
                title: '開發審議案件查詢',
                to: 'https://eland.cpami.gov.tw/nuld/Web_Plan',
              },
              {
                title:
                  '非都市土地使用分區及使用地變更審查作業專責審議小組委員名冊',
                to: '/examination-task-force/rosters',
              },
            ],
          },
          {
            title: '甲種乙種工業區總量管制',
            links: [
              { title: '甲種乙種工業區總量管制', to: '/total-amount-control' },
            ],
          },
          {
            title: '圖資查詢系統',
            links: [{ title: '圖資查詢系統', to: '/landev-gis' }],
          },
          {
            title: '公告消息',
            links: [
              { title: '最新消息', to: '/latest-news' },
              { title: '公告欄', to: '/announcements' },
            ],
          },
          {
            title: '相關連結',
            links: [{ title: '相關連結', to: '/related-link' }],
          },
        ],
        navSection: {
          isOpen: this.isWebSiteNavOpen,
        },
      }
    },
    methods: {
      ...mapMutations('layout', ['setAllMainNews', 'setMainNews']),
      changeNav(): void {
        this.navSection.isOpen = !this.navSection.isOpen
      },
      goPath(nav: any) {
        window.open(nav.to, this.isExternalPath(nav.to))
      },
    },
    props: {
      isWebSiteNavOpen: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      isWebSiteNavOpen() {
        this.navSection.isOpen = this.isWebSiteNavOpen
      },
    },
  })
