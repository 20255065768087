import axios from 'axios'

import request from '@/request'
import config from '@/config'

export const image = {
  list(params = {}): Promise<any> {
    return request.get('/banners/', { params })
  },
}

export const news = {
  get(params = {}): Promise<any> {
    return request.get('/news/', { params })
  },
  getDetail(id: string): Promise<any> {
    return request.get(`/news/${id}/`)
  },
}

export const announcements = {
  get(params = {}): Promise<any> {
    return request.get('/announcements/', { params })
  },
  getDetail(id: string): Promise<any> {
    return request.get(`/announcements/${id}/`)
  },
}

export const taskForce = {
  getRosters(params = {}): Promise<any> {
    return request.get('/examination-task-force/rosters/', { params })
  },
  getYears(): Promise<any> {
    return request.get('/examination-task-force/years/')
  },
  getRostersDetail(id: string): Promise<any> {
    return request.get(`/examination-task-force/rosters/${id}/`)
  },
}

export const urbanPlanning = {
  get(): Promise<any> {
    return request.get('/urban-planning/')
  },
  getIndustrialDistricts(urbanPlanningId: string): Promise<any> {
    return request.get(
      `/urban-planning/${urbanPlanningId}/industrial-districts/`
    )
  },
  getDetail(
    urbanPlanningId: string,
    industrialDistrictId: string
  ): Promise<any> {
    return request.get(
      `/urban-planning/${urbanPlanningId}/industrial-districts/${industrialDistrictId}/`
    )
  },
}

export function textQueryMap(query: string) {
  return axios.get(`https://api.nlsc.gov.tw/idc/TextQueryAddress/${query}/1/K`)
}

export function getListAndSection(town: string) {
  return axios.get(`https://api.nlsc.gov.tw/other/ListLandSection/K/${town}`)
}

export function getLandPositionLongitudeLatitude(
  street: string,
  section: string
) {
  return axios.get(
    `https://api.nlsc.gov.tw/dmaps/CadasMapPosition/K/${street}/${section}`
  )
}

export function getIndustryGeoJson() {
  return axios.get(`${config.apiUrl}/api/miaoli-industry-geos/`)
}

export function getDevelopmentGeoJson() {
  return axios.get(`${config.apiUrl}/api/miaoli-development-geos/`)
}

export function getRelatedLinks() {
  return axios.get(`${config.apiUrl}/api/related-websites/`)
}

export function getStrategy() {
  return axios.get(
    `${config.apiUrl}/api/regional-industrial-development-strategy/`
  )
}
