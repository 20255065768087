
  import { defineComponent } from 'vue'

  import { getRelatedLinks } from '@/service'
  import config from '@/config'
  import RelatedLinkContent from '@/views/RelatedLink/components/content/index.vue'
  import RelatedLinkContenCarsouel from '@/views/RelatedLink/components/content/carsouel/index.vue'

  export default defineComponent({
    name: 'index',
    components: { RelatedLinkContent, RelatedLinkContenCarsouel },
    data() {
      return {
        connects: new Array(5).fill({}),
      }
    },
    created() {
      getRelatedLinks()
        .then((res) => {
          ;(this.connects as any) = res.data
        })
        .catch(() => {
          ;(this.connects as any) = config.relatedLink
        })
    },
    props: {
      isCarousel: {
        type: Boolean,
        default: false,
      },
    },
  })
