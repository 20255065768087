
  import { defineComponent } from 'vue'
  import { mapActions, mapState, mapMutations } from 'vuex'
  import isExternalPath from '@/utils/isExternalPath'

  export default defineComponent({
    setup() {
      return { isExternalPath }
    },
    computed: {
      ...mapState('layout', ['navs']),
    },
    data() {
      return {}
    },
    methods: {
      ...mapMutations('layout', ['setNavsFocus', 'setAllNavsFcous']),
      ...mapActions('layout', ['setNavBarShow']),
      changeChildrenNav(nav: any, targetNav: any, value: boolean): void {
        this.setAllNavsFcous(false)
        this.setNavsFocus({ to: nav.to, focus: value })
        this.showNav()
        this.goPath(targetNav.to)
      },
      changeNav(nav: any, value: boolean): void {
        const {
          children,
          class: { focus },
        } = nav
        if (children?.length > 1) {
          this.setAllNavsFcous(false)
          if (focus) {
            return
          }
          this.setNavsFocus({ to: nav.to, focus: value })
          return
        }
        this.showNav()
        this.goPath(nav.to)
      },
      goPath(path: string): void {
        const external = this.isExternalPath(path)
        if (external === '_blank') {
          window.open(path, external)
        } else {
          this.$router.push({ path: path })
        }
      },
      showNav(): void {
        this.$emit('update:isNavShow', !this.isNavShow)
        this.$emit('showNav', this.isNavShow)
      },
      arrowDirection(focus: boolean, children: Array<any>): any {
        const className = {
          'text-white': focus,
          'bi-chevron-right': true,
          'bi-chevron-up': false,
        }
        if (children && children.length > 0) {
          className['bi-chevron-up'] = focus
          className['bi-chevron-right'] = !focus
        }

        return className
      },
    },
    props: {
      isNavShow: {
        type: Boolean,
        default: false,
      },
    },
  })
