import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-621828b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "container main-width pt-3 mb-lg-5 mb-md-4 mb-sm-2 d-flex"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "fas fa-angle-right mx-2 text-elite-blue"
}
const _hoisted_4 = {
  key: 0,
  class: "fas fa-angle-right mx-2 text-elite-blue"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.$route.name !== 'Gis')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.$route.meta.breadcrumb)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([
            ..._ctx.$route.meta.breadcrumb,
            { name: '詳細資訊' },
          ], (router, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: router.link,
                  class: _normalizeClass(["d-inline fs-12-sm fs-16 lh-25", [index && 'text-truncate']])
                }, [
                  _createVNode(_component_router_link, {
                    to: router.link || '',
                    class: _normalizeClass({
              'text-decoration-none': index !== 0,
              'text-cobalt': index === 0,
              'cursor-default': !router.link,
            })
                  }, {
                    default: _withCtx(() => [
                      index
                        ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                        : _createCommentVNode("", true),
                      _createElementVNode("span", {
                        class: _normalizeClass(["text-center fs-16 lh-25", {
                'text-cobalt': router.link,
                'text-elite-blue': !router.link,
              }])
                      }, _toDisplayString(router.name), 3)
                    ]),
                    _: 2
                  }, 1032, ["to", "class"])
                ], 2))
              }), 128))
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.routers, (router, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: router.path,
                class: _normalizeClass(["d-inline fs-12-sm fs-16 lh-25", [index && 'text-truncate']])
              }, [
                (router.meta.title)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: router.path,
                      class: _normalizeClass({
            'text-decoration-none': index !== 0,
            'text-cobalt': index === 0,
            'cursor-default': _ctx.isNowPath(router.path),
          })
                    }, {
                      default: _withCtx(() => [
                        index
                          ? (_openBlock(), _createElementBlock("i", _hoisted_4))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", {
                          class: _normalizeClass(["text-center fs-16 lh-25", {
              'text-elite-blue': _ctx.isNowPath(router.path),
              'text-cobalt': !_ctx.isNowPath(router.path),
            }])
                        }, _toDisplayString(router.meta.title), 3)
                      ]),
                      _: 2
                    }, 1032, ["to", "class"]))
                  : _createCommentVNode("", true)
              ], 2))
            }), 128))
      ]))
    : _createCommentVNode("", true)
}