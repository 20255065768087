/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const state: any = {
  totalProjects: [
    {
      project: '竹南頭份都市計畫',
      totalArea: '420,000',
      area: '14017.98',
      applyArea: '14017.98',
      accumulatedArea: { area: '0.00', percentage: '0.00%' },
    },
    {
      project: '苑裡都市計畫',
      totalArea: '300,000',
      area: '14017.98',
      applyArea: '14017.98',
      accumulatedArea: { area: '0.00', percentage: '0.00%' },
    },
  ],
  projects: [
    {
      project: '竹南頭份都市計畫',
      industryIndex: '工一甲(東側)' as string,
      totalArea: '170,000',
      area: '14017.98',
      applyArea: '14017.98',
      accumulatedArea: { area: '0.00', percentage: '0.00%' },
    },
    {
      project: '竹南頭份都市計畫',
      industryIndex: '工三甲',
      totalArea: '250,000',
      area: '14017.98',
      applyArea: '14017.98',
      accumulatedArea: { area: '0.00', percentage: '0.00%' },
    },
    {
      project: '苑裡都市計畫',
      industryIndex: '工一甲',
      totalArea: '100,000',
      area: '0',
      applyArea: '0',
      accumulatedArea: { area: '0.00', percentage: '0.00%' },
    },
    {
      project: '苑裡都市計畫',
      industryIndex: '工二甲',
      totalArea: '200,000',
      area: '0',
      applyArea: '0',
      accumulatedArea: { area: '0.00', percentage: '0.00%' },
    },
  ],
}

export const mutations = {}

export const actions = {}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
