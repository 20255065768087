<template>
  <div class="license-container">
    <div class="d-flex flex-column align-items-center mb-sm-5">
      <div class="d-flex flex-column align-items-center">
        <span class="connect-text text-elite-blue fw-bold fs-36">相關連結</span>
        <div class="connect-underline mt-2"></div>
      </div>
      <div class="container main-width mt-4">
        <div class="connect-item-wrapper text-center">
          <related-link-item
            v-for="connect in connects"
            :key="connect.name"
            :connect="connect"
          >
            {{ connect.name }}
          </related-link-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  import RelatedLinkItem from '../RelatedLinkItem/index.vue'

  export default defineComponent({
    name: 'RelatedLinkContent',
    components: {
      RelatedLinkItem,
    },
    data() {
      return {}
    },
    props: {
      connects: {
        type: Array,
        default: () => [],
      },
    },
  })
</script>

<style scoped lang="scss">
  .connect-item-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, 190px);
    grid-auto-rows: 90px;
    justify-content: center;
    gap: 30px;
  }
</style>
