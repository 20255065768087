<template>
  <router-view />
</template>

<style lang="scss">
  @import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/css/bootstrap.min.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.10.5/font/bootstrap-icons.min.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css';

  @import '@/style/CDN/styles/swiper-bundle.scss';

  @import '@/style/miao-li-landev.scss';
  @import '@/style/RWD/middle.scss';
  @import '@/style/RWD/small.scss';

  $title-height: 80px;
  $nav-height: 60px;
  $breadcrumbs-height: 89px;
  $websitenav-height: 60px;
  $footer-height: 136px;
  $banner-height: 440px;

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif, Noto Sans CJK TC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0px auto;
    font-style: normal;
    height: 100%;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .main-width {
    max-width: 1110px;
  }

  .text-cobalt {
    color: #1042a4 !important;
  }

  .bg-cobalt {
    background-color: #1042a4;
  }

  .text-dark-gray {
    color: #333333 !important;
  }

  .text-elite-blue {
    color: #172a4f;
  }

  .cursor-default {
    cursor: default;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .fw-bold {
    font-weight: bold;
  }

  .underline-border-top {
    border-top: 3px solid #1042a4;
  }

  .license-container,
  .detail-container {
    min-height: calc(
      100% - $title-height - $nav-height - $breadcrumbs-height -
        $websitenav-height - $footer-height
    );
  }

  .gis-container {
    height: calc(
      100% - $title-height - $nav-height - $websitenav-height - $footer-height
    ) !important;
  }

  .bg-gray {
    background: #f2f2f2;
  }

  .table-header {
    background: #172a4f;
    height: 60px;
  }

  .text-right {
    text-align: right;
  }

  .hamburger {
    display: none;
  }

  .table-width {
    min-width: 1086px !important;
  }

  .object-fi-cover {
    object-fit: cover;
  }

  .ant-pagination-item a {
    color: #1042a4 !important;
  }
</style>
