import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c9927c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carsouel mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_banner = _resolveComponent("banner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "swiper-button-prev",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.slidePrev && _ctx.slidePrev(...args)))
    }),
    _createVNode(_component_banner, {
      ref: "banner",
      slides: _ctx.connects.length ? _ctx.connects : _ctx.slides,
      breakpoints: _ctx.breakpoints,
      navigation: false,
      "is-link": ""
    }, null, 8, ["slides", "breakpoints"]),
    _createElementVNode("div", {
      class: "swiper-button-next",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.slideNext && _ctx.slideNext(...args)))
    })
  ]))
}