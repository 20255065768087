/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const state: any = {
  isNavBarShow: false,
  navs: [
    {
      title: '地方產業發展策略',
      to: '/development-strategy',
      class: { focus: false },
    },
    {
      title: '非都市土地開發許可',
      to: '/examination-task-force/rosters',
      class: { focus: false },
      children: [
        {
          title: '開發審議案件查詢',
          to: 'https://eland.cpami.gov.tw/nuld/Web_Plan',
        },
        {
          title: '非都市土地使用分區及使用地變更審查作業專責審議小組委員名冊',
          to: '/examination-task-force/rosters',
        },
      ] as any[],
    },
    {
      title: '甲種乙種工業區總量管制',
      to: '/total-amount-control',
      class: { focus: false },
    },
    { title: '圖資查詢系統', to: '/landev-gis', class: { focus: false } },
    { title: '相關連結', to: '/related-link', class: { focus: false } },
  ] as any[],
  navChlidren: [] as any[],
  mainNews: [
    { title: '最新消息', focus: true },
    { title: '公告欄', focus: false },
  ],
}

const getters = {
  getNews: (state: any) => {
    return state.mainNews
  },

  getFocusNews: (state: any) => {
    return state.mainNews.filter((news: any) => news.focus)[0]
  },
}

export const mutations = {
  setNavBarShow(state: any, isNavBarShow: boolean): void {
    state.isNavBarShow = isNavBarShow
  },
  setNavsFocus(state: any, nav: { to: string; focus: boolean }): void {
    state.navs.map((item: { to: string; class: { focus: boolean } }) => {
      if (item.to === nav.to) {
        item.class.focus = nav.focus
      } else {
        item.class.focus = !nav.focus
      }
    })
  },
  setNavsChildren(state: any, children: any): void {
    state.navChlidren = children
  },
  setAllNavsFcous(state: any, value: boolean): void {
    state.navs.map((item: { class: { focus: boolean } }) => {
      item.class.focus = value
    })
  },
  setMainNews(state: any, news: { title: string; focus: boolean }) {
    state.mainNews.map((item: any) => {
      if (item.title === news.title) {
        item.focus = news.focus
      }
    })
  },
  setAllMainNews(state: any, value: boolean) {
    state.mainNews.map((item: any) => {
      item.focus = value
    })
  },
}

export const actions = {
  setNavBarShow({ commit }: any, isNavBarShow: boolean): void {
    commit('setNavBarShow', isNavBarShow)
  },
  setNavsFocus({ commit }: any, nav: { to: string; focus: boolean }): void {
    commit('setNavsFocus', nav)
  },
  setNavsChildren({ commit }: any, children: any): void {
    commit('setNavsChildren', children)
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
}
