export default {
  relatedLink: [],
  layers: [
    {
      name: '臺灣通用電子地圖',
      value: 'https://wms.nlsc.gov.tw/wms?LAYERS=EMAP',
    },
    {
      name: '正射影像(航照圖)',
      value: 'https://wms.nlsc.gov.tw/wms?LAYERS=PHOTO2',
    },
  ],
  overlays: [
    {
      name: '都市計畫使用分區圖',
      value:
        'https://landev.miaoli.gov.tw/proxy/URBAN_LANDUSE_ZONE/MapServer/WMSServer?token=nfjcJSkWMnQjp4Nc_EnHEYypqeOKvS0vuWfu7v3x-o7z6d9e55qKcqM7-iAuGLmqLazC3X4jmSH0twxe1Z_osCQO_a1SYFxEFJPiO-CgxRg.',
    },
    {
      name: '非都市土地使用分區圖',
      value:
        'https://landev.miaoli.gov.tw/proxy/NON_URBAN_LANDUSE_ZONE/ImageServer/WMSServer?token=nfjcJSkWMnQjp4Nc_EnHEYypqeOKvS0vuWfu7v3x-o7z6d9e55qKcqM7-iAuGLmqLazC3X4jmSH0twxe1Z_osCQO_a1SYFxEFJPiO-CgxRg.',
    },
    {
      name: '非都市土地使用編定',
      value:
        'https://landev.miaoli.gov.tw/proxy/NON_URBAN_LANDUSE_LAND/ImageServer/WMSServer?token=nfjcJSkWMnQjp4Nc_EnHEYypqeOKvS0vuWfu7v3x-o7z6d9e55qKcqM7-iAuGLmqLazC3X4jmSH0twxe1Z_osCQO_a1SYFxEFJPiO-CgxRg.',
    },
    {
      name: '臺灣通用電子地圖(透明)',
      value: 'https://wms.nlsc.gov.tw/wms?LAYERS=EMAP2',
    },
    { name: '地籍圖', value: 'dmap' },
    { name: '苗栗縣核准開發計畫案件圖', value: 'development-geos' },
    { name: '苗栗縣都市計畫工業區編號圖', value: 'industry-geos' },
  ],
  apiUrl: process.env.VUE_APP_API_URL,
  towns: [
    {
      towncode: 'K01',
      townname: '苗栗市',
    },
    {
      towncode: 'K02',
      townname: '苑裡鎮',
    },
    {
      towncode: 'K03',
      townname: '通霄鎮',
    },
    {
      towncode: 'K04',
      townname: '公館鄉',
    },
    {
      towncode: 'K05',
      townname: '銅鑼鄉',
    },
    {
      towncode: 'K06',
      townname: '三義鄉',
    },
    {
      towncode: 'K07',
      townname: '西湖鄉',
    },
    {
      towncode: 'K08',
      townname: '頭屋鄉',
    },
    {
      towncode: 'K09',
      townname: '竹南鎮',
    },
    {
      towncode: 'K10',
      townname: '頭份市',
    },
    {
      towncode: 'K11',
      townname: '造橋鄉',
    },
    {
      towncode: 'K12',
      townname: '後龍鎮',
    },
    {
      towncode: 'K13',
      townname: '三灣鄉',
    },
    {
      towncode: 'K14',
      townname: '南庄鄉',
    },
    {
      towncode: 'K15',
      townname: '大湖鄉',
    },
    {
      towncode: 'K16',
      townname: '卓蘭鎮',
    },
    {
      towncode: 'K17',
      townname: '獅潭鄉',
    },
    {
      towncode: 'K18',
      townname: '泰安鄉',
    },
  ],
}
