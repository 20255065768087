
  /* eslint-disable vue/no-unused-components */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  import { defineComponent } from 'vue'
  import { mapActions, mapState, mapMutations } from 'vuex'
  import FooterWrapper from '@/components/Footer/index.vue'
  import Breadcrumb from '@/components/Breadcrumb/index.vue'
  import Hamburger from '@/components/Hamburger/index.vue'
  import isExternalPath from '@/utils/isExternalPath'

  export default defineComponent({
    name: '',
    setup() {
      return { isExternalPath }
    },
    components: {
      FooterWrapper,
      Breadcrumb,
      Hamburger,
    },
    data() {
      return {
        isWebSiteNavOpen: false,
        isShowNav: false,
        Iscontentshow: true,
      }
    },
    computed: {
      ...mapState('layout', ['isNavBarShow', 'navs', 'navChlidren']),
      isHome() {
        return this.$route.path === '/'
      },
    },
    methods: {
      ...mapMutations('layout', ['setAllNavsFcous']),
      ...mapActions('layout', [
        'setNavBarShow',
        'setNavsFocus',
        'setNavsChildren',
      ]),
      goHome(): void {
        this.$router.push('/')
        this.isWebSiteNavOpen = true
        this.isShowNav = false
        this.Iscontentshow = true
        this.setAllNavsFcous(false)
      },
      changeNavBarShow(nav: any): void {
        if (nav && nav.children) {
          this.setNavsChildren(nav.children)
        } else {
          this.setNavsChildren([])
        }
      },
      showNav(value: boolean): void {
        this.isShowNav = !value
        this.Iscontentshow = value
      },
    },
    watch: {
      $router: {
        deep: true,
        immediate: true,
        handler() {
          const path = this.$route.path
          if (path === '/') {
            this.isWebSiteNavOpen = true
          }

          this.navs.map((item: { to: string; class: { focus: boolean } }) => {
            if (item.to === path) {
              this.setNavsFocus({ to: path, focus: true })
            }
          })

          if (path === '/open-data-license' || path === '/privacy-policy') {
            this.setAllNavsFcous(false)
          }
        },
      },
      isShowNav(value: boolean) {
        const appElement: any = document.getElementById('app')
        if (!appElement) {
          return
        }

        if (value) {
          appElement.style.backgroundColor = '#f6f8fc'
        } else {
          appElement.style.backgroundColor = '#ffffff'
        }

        // this.setNavBarShow(value)
      },
    },
  })
