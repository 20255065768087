<template>
  <div
    class="banner-wrapper text-center d-flex justify-content-center align-items-center mb-4"
  >
    <div class="banner">
      <banner
        :key="slides.length"
        :slides="slides"
        :pagination="paginationSetting"
        :autoplay="{ delay: 10 * 1000, disableOnInteraction: false }"
      />
    </div>
  </div>
  <div class="container content text-center main-width">
    <div class="row">
      <div class="col-12 px-3 px-lg-0">
        <div
          class="border-bottom d-flex flex-row justify-content-center gap-sm-3"
        >
          <div
            v-for="main in getNews"
            class="d-flex flex-row justify-content-center main-news"
            :key="main.title"
          >
            <div
              class="underline w-145px w-100-sm pt-4 lh-25 cursor-pointer"
              :class="[
                {
                  'underline-hover': main.focus,
                  'text-dark-gray': !main.focus,
                },
              ]"
              @click="changeMainNews(main)"
            >
              <span>{{ main.title }}</span>
            </div>
          </div>
        </div>
        <content-table
          :newsList="isFocusNews ? newsList : announcementList"
          @click-item="handleClickItem"
        />
        <div class="my-4">
          <router-link
            :to="{
              name:
                getFocusNews?.title === '最新消息'
                  ? 'LatestNews'
                  : 'Announcements',
            }"
            custom
            v-slot="{ navigate }"
          >
            <button type="button" class="more-news-btn btn" @click="navigate">
              <span class="text-cobalt">查看更多消息</span>
            </button>
          </router-link>
        </div>
        <div
          class="d-flex flex-column align-items-center justify-content-center mt-5"
        >
          <span class="fs-24 fw-bold">相關連結</span>
          <hr class="bg-cobalt mb-0 mt-3" />
        </div>
        <related-link isCarousel class="mt-4 mb-5" />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import { mapMutations, mapGetters } from 'vuex'

  import RelatedLink from '@/views/RelatedLink/index.vue'
  import ContentTable from '@/components/ContentTable/index.vue'
  import Banner from '@/components/Banner/index.vue'
  import { news, announcements, image } from '@/service'

  export default defineComponent({
    name: 'Home',
    components: {
      RelatedLink,
      ContentTable,
      Banner,
    },
    created() {
      this.setAllNavsFcous(false)
      news.get().then(({ data }) => {
        this.newsList = data.results.filter((item, index) => {
          return index < 5
        })
      })

      announcements.get().then(({ data }) => {
        this.announcementList = data.results.filter((item, index) => {
          return index < 5
        })
      })

      image.list().then((res) => {
        const { data } = res
        this.slides = data.map((item) => {
          return {
            src: item.image,
          }
        })
      })
    },
    computed: {
      ...mapGetters('layout', ['getNews', 'getFocusNews']),
      isFocusNews() {
        return !!this.getNews?.[0].focus
      },
    },
    data() {
      return {
        announcementList: [],
        newsList: [],
        slides: [],
        paginationSetting: {
          clickable: true,
        },
      }
    },
    methods: {
      ...mapMutations('layout', [
        'setAllNavsFcous',
        'setMainNews',
        'setAllMainNews',
      ]),
      changeMainNews(main) {
        this.setAllMainNews(false)
        this.setMainNews({ title: main.title, focus: true })
      },
      handleClickItem(item) {
        this.$router.push({
          name: this.isFocusNews ? 'NewsDetail' : 'AnnouncementDetail',
          params: { id: item.id || 1 },
        })
      },
    },
  })
</script>

<style scoped lang="scss">
  .banner-wrapper {
    height: 440px;
    background: linear-gradient(93.3deg, #042a62 2.91%, #1d7d9b 98.61%);
  }

  .banner {
    position: relative;
    z-index: 2;
    width: 1230px;
  }

  .more-news-btn {
    width: 19%;
    border-radius: 0;
    border-color: #1042a4;
  }

  .underline {
    font-size: 20px;
  }

  .underline-hover {
    color: #1042a4;
    cursor: default;
    box-shadow: 0px 3px #1042a4;
  }

  .connect-underline {
    border-top: 3px solid #1042a4;
    width: 46px;
  }

  .main-news {
    height: 58px;
    width: 558px;
  }

  hr {
    opacity: 1;
    width: 3rem;
    height: 3px;
  }

  .w-145px {
    width: 145px;
  }

  .w-100-sm {
    @media (max-width: 576px) {
      width: 100% !important;
    }
  }
</style>
