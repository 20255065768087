import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_related_link_content = _resolveComponent("related-link-content")!
  const _component_related_link_conten_carsouel = _resolveComponent("related-link-conten-carsouel")!

  return (!_ctx.isCarousel)
    ? (_openBlock(), _createBlock(_component_related_link_content, {
        key: 0,
        connects: _ctx.connects
      }, null, 8, ["connects"]))
    : (_openBlock(), _createBlock(_component_related_link_conten_carsouel, {
        key: 1,
        connects: _ctx.connects
      }, null, 8, ["connects"]))
}