
  import { defineComponent } from 'vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import Swiper2, { Navigation, Pagination, Autoplay } from 'swiper'

  import RelatedLinkItem from '@/views/RelatedLink/components/RelatedLinkItem/index.vue'

  export default defineComponent({
    components: {
      Swiper,
      SwiperSlide,
      RelatedLinkItem,
    },
    data() {
      return {
        swiper: {},
      } as { swiper: { slidePrev: () => void; slideNext: () => void } }
    },
    computed: {
      paginationSetting() {
        const keys = Object.keys(this.pagination)
        if (keys.length > 0) {
          return this.pagination
        }
        return false
      },
    },
    setup(props) {
      Swiper2.use([Navigation, Pagination, Autoplay])
    },
    methods: {
      slideNext() {
        this.swiper.slideNext()
      },
      slidePrev() {
        this.swiper.slidePrev()
      },
      onSwiper(swiper: any) {
        this.swiper = swiper
      },
    },
    props: {
      isLink: {
        type: Boolean,
        default: false,
      },
      slides: {
        type: Array,
        default: () => [],
      },
      breakpoints: {
        type: Object,
        default: () => {
          return {}
        },
      },
      pagination: {
        type: Object,
        default: () => {
          return {}
        },
      },
      navigation: {
        type: Boolean,
        default: true,
      },
      autoplay: {
        type: Object,
        default: () => ({ delay: Date.now() / 1000 }),
      },
    },
  })
