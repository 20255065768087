
  import { defineComponent } from 'vue'

  import config from '@/config'
  import { getRelatedLinks } from '@/service'
  import Banner from '@/components/Banner/index.vue'

  export default defineComponent({
    components: {
      Banner,
    },
    created() {
      // getRelatedLinks()
      //   .then((res) => {
      //     ;(this.slides as any) = res.data
      //   })
      //   .catch(() => {
      //     ;(this.slides as any) = config.relatedLink
      //   })
    },
    props: {
      connects: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        slides: new Array(5).fill({}),
        imageMiddle: 190,
        imageSmall: 153,
        breakpoints: {
          1024: {
            slidesPerView: 5,
            spaceBetween: 0,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          375: {
            slidesPerView: 2,
            spaceBetween: 0,
          },
        },
      }
    },
    methods: {
      slideNext() {
        ;(this.$refs.banner as any).slideNext()
      },
      slidePrev() {
        ;(this.$refs.banner as any).slidePrev()
      },
    },
  })
