import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ff1b776"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_related_link_item = _resolveComponent("related-link-item")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    navigation: _ctx.navigation,
    slidesPerView: 1,
    autoplay: _ctx.autoplay,
    breakpoints: _ctx.breakpoints,
    pagination: _ctx.paginationSetting,
    onSwiper: _ctx.onSwiper
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide) => {
        return (_openBlock(), _createBlock(_component_swiper_slide, {
          key: slide.src
        }, {
          default: _withCtx(() => [
            (_ctx.isLink)
              ? (_openBlock(), _createBlock(_component_related_link_item, {
                  key: 0,
                  connect: slide
                }, null, 8, ["connect"]))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: slide.src,
                  class: "object-fi-cover"
                }, null, 8, _hoisted_1))
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["navigation", "autoplay", "breakpoints", "pagination", "onSwiper"]))
}