import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Layout from '../layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Layout,
    meta: {
      title: '首頁',
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        meta: {
          title: '苗栗縣地方產業發展策略資訊網',
        },
      },
      {
        path: '/development-strategy',
        name: 'DevelopmentStrategy',
        component: () => import('../views/DevelopmentStrategy/index.vue'),
        meta: {
          title: '地方產業發展策略',
        },
      },
      {
        path: '/examination-task-force/rosters',
        name: 'DevelopmentLicense',
        component: () =>
          import('../views/DevelopmentLicense/components/Rosters/index.vue'),
        meta: {
          title: '非都市土地使用分區及使用地變更審查作業專責審議小組委員名冊',
        },
        children: [
          {
            path: '/',
            name: 'DevelopmentLicense',
            component: () =>
              import(
                '../views/DevelopmentLicense/components/DevelopmentLicense/index.vue'
              ),

            children: [],
          },
          {
            path: '',
            name: 'Rosters',
            component: () =>
              import(
                '../views/DevelopmentLicense/components/Rosters/index.vue'
              ),
          },
        ],
      },
      {
        path: '/examination-task-force/:id',
        name: 'RosterDetail',
        component: () => import('../views/Detail/index.vue'),
        meta: {
          title: '詳細資訊',
          breadcrumb: [
            { name: '首頁', link: '/' },
            {
              name: '非都市土地使用分區及使用地變更審查作業專責審議小組委員名冊',
              link: '/examination-task-force/rosters',
            },
          ],
        },
      },
      {
        path: '/total-amount-control',
        name: 'TotalAmountControl',
        component: () => import('../views/TotalAmountControl/index.vue'),
        meta: {
          title: '甲種乙種工業區總量管制',
        },
      },
      {
        path: '/landev-gis',
        name: 'Gis',
        component: () => import('../views/Gis/index.vue'),
        meta: {
          title: '圖資查詢系統',
        },
      },
      {
        path: '/related-link',
        name: 'RelatedLink',
        component: () => import('../views/RelatedLink/index.vue'),
        meta: {
          title: '相關連結',
        },
      },
      {
        path: '/news',
        name: 'LatestNews',
        component: () => import('../views/LatestNews/index.vue'),
        meta: {
          title: '最新消息',
        },
      },
      {
        path: '/news/:id',
        name: 'NewsDetail',
        component: () => import('../views/Detail/index.vue'),
        meta: {
          title: '詳細資訊',
          breadcrumb: [
            { name: '首頁', link: '/' },
            { name: '最新消息', link: '/news' },
          ],
        },
      },
      {
        path: '/announcements',
        name: 'Announcements',
        component: () => import('../views/Announcements/index.vue'),
        meta: {
          title: '公告欄',
        },
      },
      {
        path: '/announcements/:id',
        name: 'AnnouncementDetail',
        component: () => import('../views/Detail/index.vue'),
        meta: {
          title: '詳細資訊',
          breadcrumb: [
            { name: '首頁', link: '/' },
            { name: '公告欄', link: '/announcements' },
          ],
        },
      },
      {
        path: '/open-data-license',
        name: 'OpenDataLicense',
        component: () => import('../views/OpenDataLicense/index.vue'),
        meta: {
          title: '政府網站資料開放宣告',
        },
      },
      {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy/index.vue'),
        meta: {
          title: '隱私權與資訊安全政策',
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title as string
  }
  next()
  setTimeout(() => window.scrollTo(0, 0), 100)
})

router.afterEach(() => {
  const bodyElement: any = document.querySelector('body')
  bodyElement.style.maxHeight = `${window.innerHeight}px`
  bodyElement.style.overflow = 'hidden'

  setTimeout(() => {
    window.scrollTo(0, 0)
    bodyElement.style.maxHeight = null
    bodyElement.style.overflow = 'initial'
  }, 50)
})

export default router
