<template>
  <a
    class="connect-item d-flex align-items-center justify-content-center fw-bold text-decoration-none"
    :key="connect.name"
    :href="connect.url"
    target="_blank"
  >
    {{ connect.name }}
  </a>
</template>

<script>
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      connect: {
        required: true,
      },
    },
  })
</script>

<style lang="scss" scoped>
  .connect-item {
    width: 190px;
    height: 90px;
    background: #e5ebf5;
    color: #1042a4;
  }
</style>
