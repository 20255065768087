import { createStore } from 'vuex'
import layout from './layout'
import project from './project'

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: { layout, project },
})
